import React from 'react';
import styled from 'styled-components';
import { arrayOf, shape } from 'prop-types';
import blogArticleProps from 'TMPropTypes/blogArticleProps';
import FlexWrapper from 'TMComponents/FlexWrapper';
import CategoryPill from 'TMComponents/CategoryPill';
import NavLink from 'TMComponents/NavLink';
import { H3, Body } from 'TMComponents/Typography';
import ArticleDate from 'TMComponents/ArticleDate';
import Author from 'TMComponents/Author';
import Img from 'TMComponents/ImageDynamic';
import { breakpoints } from 'TMConstants';

const Card = styled(FlexWrapper)`
    background-color: ${({ theme }) => theme.colorSystem.primary[3]};
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.colorSystem.shadows['8']};
    margin-bottom: 20px;
    width: 100%;
    height: 100%;
    @media (min-width: ${breakpoints.mobileTablet}px) {
        height: auto;
    }

    @media (min-width: ${breakpoints.mobileTablet}px) {
        width: calc(50% - 5px);

        &:nth-child(2n + 1) {
            margin-right: 10px;
        }
    }

    @media (min-width: ${breakpoints.tabletDesktop}px) {
        width: calc(50% - 15px);

        &:nth-child(2n + 1) {
            margin-right: 30px;
        }
    }
`;

const CardImg = styled(Img)`
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 200px;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        height: 350px;
    }
`;

const Inner = styled(FlexWrapper)`
    padding: 0px 20px 20px 20px;
    flex: 1 1 auto;
`;

const Title = styled(H3)`
    margin-bottom: 10px;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        margin-bottom: 10px;
    }
`;

const MetaText = styled(Body)`
    color: ${({ theme }) => theme.colorSystem.UIText[2]};
    margin: 0;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        margin: 0;
    }
`;

const CategoryWrapper = styled(FlexWrapper)`
    gap: 5px;
    margin-top: 20px;
`;

const ArticleCardList = ({ articles }) => (
    <FlexWrapper wrap>
        {articles.length === 0 && (
            <MetaText>
                Sorry, we couldn&apos;t find any articles...
            </MetaText>
        )}
        {articles.map(
            ({
                id,
                slug,
                date,
                title,
                featuredImage,
                author,
                summary,
                categories
            }) => (
                <Card key={id} direction="column">
                    <NavLink href={`/blog/${slug}/`} aria-label={title}>
                        <CardImg imageData={featuredImage} />
                    </NavLink>
                    <Inner direction="column" justify="space-between">
                        <div>
                            <NavLink href={`/blog/${slug}/`}>
                                <Title>{title}</Title>
                            </NavLink>
                            <ArticleDate date={date} />
                            <Author {...author} />
                            <MetaText>{summary}</MetaText>
                        </div>
                        <CategoryWrapper wrap>
                            {categories.map(({ name }) => (
                                <CategoryPill key={name} name={name} />
                            ))}
                        </CategoryWrapper>
                    </Inner>
                </Card>
            )
        )}
    </FlexWrapper>
);

ArticleCardList.propTypes = {
    articles: arrayOf(shape(blogArticleProps)).isRequired
};

export default ArticleCardList;
