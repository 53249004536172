/**
 * Section component, used to break up content on the blog page
 */
import React from 'react';
import styled from 'styled-components';
import { string, node } from 'prop-types';

import { H2 } from 'TMComponents/Typography';
import { breakpoints } from 'TMConstants';

const SectionWrapper = styled.div`
    padding-bottom: 20px;

    &:first-child {
        padding-top: 20px;
    }

    @media (min-width: ${breakpoints.mobileTablet}px) {
        padding-bottom: 25px;
        &:first-child {
            padding-top: 60px;
        }
    }
`;

const TitleWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    position: relative;
    text-align: center;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        margin-bottom: 40px;
    }
`;

const Title = styled(H2)`
    display: inline-block;
    margin: 0;
    position: relative;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        margin: 0;
    }
`;

const Line = styled.div`
    display: none;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        display: block;
        background-color: ${(props) => props.theme.colors.greys.regular};
        border-radius: 3px;
        height: 3px;
        margin: 0 20px;
        position: relative;
        width: 10%;
        height: 3px;
    }
`;

const Section = ({ title, children }) => (
    <SectionWrapper>
        <TitleWrapper>
            <Line left />
            <Title variant>{title}</Title>
            <Line right />
        </TitleWrapper>
        {children}
    </SectionWrapper>
);

Section.propTypes = {
    title: string.isRequired,
    children: node.isRequired
};

export default Section;
