/* SEARCH-TAG: page-template  */
import React, {
    useState,
    Suspense,
    lazy,
    useEffect,
    useContext
} from 'react';
import { graphql } from 'gatsby';
import { shape, arrayOf } from 'prop-types';
import styled from 'styled-components';
import blogArticleProps from 'TMPropTypes/blogArticleProps';
import categoryProps from 'TMPropTypes/categoryProps';
import HeadTags from 'TMComponents/HeadTags';
import Main from 'TMComponents/Layout/Main';
import FlexWrapper from 'TMComponents/FlexWrapper';
import Container from 'TMComponents/Container';
import Input from 'TMComponents/form/Input';
import { Button } from 'TMComponents/Button';
import Canonical from 'TMComponents/Canonical';
import { BreadcrumbsContext } from 'TMComponents/Breadcrumbs';
import { BannerContext } from 'TMComponents/Banner';
import Hero from 'TMComponents/Hero';
import isServerSideRender from 'TMUtils/isServerSideRender';
import { LayoutContext } from 'TMComponents/Layout';
import Section from './_Section';
import CategoryFilter from './_CategoryFilter';
import ArticleCardList from './_ArticleCardList';

const Content = styled(Container)`
    max-width: 1280px;
    padding: 0 16px;
`;

const ButtonWrapper = styled(FlexWrapper)`
    margin: 10px 0;
`;

const ServerSideRender = isServerSideRender();
const TrendingArticleCards = lazy(() => import('./_TrendingArticleCards'));

const BlogLandingPage = ({ data }) => {
    const [filter, setFilter] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [pageNo, setPageNo] = useState(1);

    let articles = data.allArticles.nodes.filter(({ title, summary }) => {
        const lcFilter = filter.toLowerCase();
        return (
            title.toLowerCase().includes(lcFilter) ||
            summary.toLowerCase().includes(lcFilter)
        );
    });

    // If not filtering by text and we've selected a category to filter - filter
    if (!filter && selectedCategories.length > 0) {
        articles = articles.filter(({ categories }) => {
            if (selectedCategories.length === 0) {
                return true;
            }
            return !!categories.find(({ name }) =>
                selectedCategories.includes(name)
            );
        });
    }
    const isBannerAllowed = true;
    const isCrumbsAllowed = false;

    const { setIsNavHidden } = useContext(LayoutContext);
    const { setShowBanner } = useContext(BannerContext);
    const { setCrumbs } = useContext(BreadcrumbsContext);

    useEffect(() => {
        setIsNavHidden(false);
        setShowBanner(isBannerAllowed);
        setCrumbs({
            show: isCrumbsAllowed,
            crumbList: [{ title: 'Blog', link: '/blog/' }]
        });
    }, []);

    return (
        <Main
            isBannerAllowed={isBannerAllowed}
            isCrumbsAllowed={isCrumbsAllowed}>
            <HeadTags tags={data.pageContent.seoMetaTags.tags} />
            <Canonical href="blog" />
            <Hero
                content={data.pageContent.heroContent}
                desktopImage={data.pageContent.headerImageDesktop}
                mobileImage={data.pageContent.headerImageMobile}
                desktopImagePosition={
                    data.pageContent.desktopImagePosition
                }
                mobileImagePosition={data.pageContent.mobileImagePosition}
                mobileImageHeight={data.pageContent.mobileImageHeight}
                imageFullHeight={data.pageContent.imageFullHeight}
                imageCover={data.pageContent.imageCover}
                backgroundVariant={data.pageContent.backgroundVariant}>
                <Input
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    placeholder={data.pageContent.searchPlaceholder}
                    search
                />
            </Hero>
            <Content>
                {!filter && (
                    <Section title={data.pageContent.trendingText}>
                        {!ServerSideRender && (
                            <Suspense fallback={<div />}>
                                <TrendingArticleCards
                                    articles={data.trendingArticles.nodes}
                                />
                            </Suspense>
                        )}
                    </Section>
                )}
                <Section
                    title={
                        filter
                            ? 'Search results'
                            : data.pageContent.latestArticleText
                    }>
                    {!filter && (
                        <CategoryFilter
                            categories={data.categories.nodes}
                            selected={selectedCategories}
                            onClick={setSelectedCategories}
                        />
                    )}
                    <ArticleCardList
                        articles={
                            filter
                                ? articles
                                : articles.slice(0, 6 * pageNo)
                        }
                    />
                    {!filter && articles.length > 6 * pageNo - 1 && (
                        <ButtonWrapper justify="center">
                            <Button onClick={() => setPageNo(pageNo + 1)}>
                                Load more articles
                            </Button>
                        </ButtonWrapper>
                    )}
                </Section>
            </Content>
        </Main>
    );
};

/**
 * Fetch page data
 */
export const pageQuery = graphql`
    {
        pageContent: datoCmsBlogLandingPage {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            trendingText
            latestArticleText
            headerImageDesktop {
                gatsbyImageData(
                    width: 768
                    layout: CONSTRAINED
                    imgixParams: { auto: "compress" }
                )
            }
            headerImageMobile {
                gatsbyImageData(
                    width: 768
                    layout: CONSTRAINED
                    imgixParams: { auto: "compress" }
                )
            }
            searchPlaceholder
            desktopImagePosition
            mobileImagePosition
            imageCover
            backgroundVariant
            heroContent {
                ...textStructuredFields
            }
        }
        categories: allDatoCmsBlogCategory(limit: 100) {
            nodes {
                name
            }
        }
        trendingArticles: allDatoCmsBlogArticle(
            filter: { trending: { eq: true } }
            limit: 6
        ) {
            nodes {
                __typename
                id
                title
                slug
                featuredImage {
                    gatsbyImageData(
                        width: 500
                        height: 200
                        layout: CONSTRAINED
                    )
                }
                categories {
                    name
                }
            }
        }
        allArticles: allDatoCmsBlogArticle(
            limit: 100
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                __typename
                id
                title
                slug
                date(formatString: "Do MMM YYYY")
                summary
                author {
                    name
                    photo {
                        gatsbyImageData(
                            width: 40
                            layout: FIXED
                            imgixParams: { auto: "compress" }
                        )
                    }
                }
                featuredImage {
                    gatsbyImageData(width: 800, layout: CONSTRAINED)
                }
                categories {
                    name
                }
            }
        }
    }
`;

/**
 * Prop types
 */
BlogLandingPage.propTypes = {
    data: shape({
        categories: shape({
            nodes: arrayOf(shape(categoryProps))
        }),
        allArticles: shape({
            nodes: arrayOf(shape(blogArticleProps))
        }),
        trendingArticles: shape({
            nodes: arrayOf(shape(blogArticleProps))
        })
    }).isRequired
};

export default BlogLandingPage;
