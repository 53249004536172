import React from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
import styled from 'styled-components';
import categoryProps from 'TMPropTypes/categoryProps';
import { breakpoints } from 'TMConstants';
import FlexWrapper from 'TMComponents/FlexWrapper';
import CategoryPill from 'TMComponents/CategoryPill';
import { Body, Bold } from 'TMComponents/Typography';

const Wrapper = styled(FlexWrapper)`
    background-color: ${({ theme }) => theme.colorSystem.primary[3]};
    border: 1px solid ${({ theme }) => theme.colorSystem.primary[3]};
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.colorSystem.shadows['5']};
    margin-bottom: 32px;
    padding: 16px;
`;

const Controls = styled(FlexWrapper)`
    height: 32px;
    margin: 8px 0;
    width: 100%;
`;

const FilterText = styled(Body)`
    margin-bottom: 0;
    margin-right: 8px;
`;

const ResetFilters = styled(Body)`
    color: ${({ theme }) => theme.colorSystem.primary[4]};
    &:hover {
        color: ${({ theme }) => theme.colorSystem.primary[1]};
    }
    &:focus {
        color: ${({ theme }) => theme.colorSystem.primary[1]};
    }
    cursor: pointer;
    ${Bold}
    margin-bottom: 0;
`;

const CategoryList = styled.div`
    overflow-x: scroll;
    white-space: nowrap;

    @media (min-width: ${breakpoints.mobileTablet}px) {
        white-space: normal;
    }
`;

const CategoryFilter = ({ categories, selected, onClick }) => (
    <Wrapper align="center" wrap>
        <FilterText>Filter blog posts by category:</FilterText>
        <Controls>
            {selected.length > 0 && (
                <ResetFilters onClick={() => onClick([])}>
                    Reset filters
                </ResetFilters>
            )}
        </Controls>
        <CategoryList>
            {categories.map(({ name }) => (
                <CategoryPill
                    key={name}
                    allowToggle
                    name={name}
                    isActive={selected.includes(name)}
                    onClick={() => {
                        const newCategories = selected.slice();
                        if (newCategories.includes(name)) {
                            newCategories.splice(
                                newCategories.indexOf(name),
                                1
                            );
                        } else {
                            newCategories.push(name);
                        }
                        onClick(newCategories);
                    }}
                />
            ))}
        </CategoryList>
    </Wrapper>
);

CategoryFilter.propTypes = {
    categories: arrayOf(shape(categoryProps)).isRequired,
    selected: arrayOf(string).isRequired,
    onClick: func.isRequired
};

export default CategoryFilter;
